#testimonials {
    -webkit-box-shadow: inset 0 0 20px #000000;
    -moz-box-shadow: inset 0 0 20px #000000;
    -o-box-shadow: inset 0 0 20px #000000;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.60);
    text-align: center;
    color: #8a6474;
    padding:80px 20px;
}

#testimonials .item p {
    line-height: 1.5em;
    font-size: 1.3em;
    font-style: italic;
    font-weight: 600;
}

#testimonials .item p.person {
    margin-top:20px;
    font-weight:600;
    font-size: 0.9em;
    font-style: normal;
}

.slick-prev, .slick-next {
    position: absolute;
    display: block;
    height: 40px;
    width: 20px;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 40%;
    margin-top: -10px;
    padding: 0;
    border: none;
    outline: none;
}

.slick-prev:before, .slick-next:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 40px;
    line-height: 1;
    color: black;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev { left: -100px; }
[dir="rtl"] .slick-prev { left: auto; right: -100px; }
.slick-prev:before { content: "\f104"; }
[dir="rtl"] .slick-prev:before { content: "\f105"; }

.slick-next { right: -100px; }
[dir="rtl"] .slick-next { left: -100px; right: auto; }
.slick-next:before { content: "\f105"; }
[dir="rtl"] .slick-next:before { content: "\f104"; }

.slick-prev:before, .slick-next:before {display:none;}

.slick-prev:before,
.slick-next:before {color:white;}
.slick-dots li button:before { color: white;}
.slick-dots li.slick-active button:before { color: white; }

@media (min-width: 1100px) {
    .slick-prev:before, .slick-next:before {display:block;}
}
