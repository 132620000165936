#resume {
    background: #eaeaea;
}

#resume .items {
    padding: 0;
    margin: 0;
}

#resume .item {
    list-style: none;
    display: block;
    width: 100%;
    float: left;
    position: relative;
    padding-bottom: 10px;
}

#resume .bullet {
    display: none;
}

#resume .box {
    background-color: #fefefe;
    padding: 20px 30px 20px 30px;
    width: 100%;
    float: right;
    position: relative;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    border-radius: 5px;
    -webkit-transition: background 0.2s linear;
    -moz-transition: background 0.2s linear;
    -o-transition: background 0.2s linear;
    transition: background 0.2s linear;
}

#resume .box:hover {
    background: #fff;
}

#resume .box:hover:after {
    border-bottom: 10px solid #fff;
}

#resume .box .period {
    margin: 4px 0 0 0;
    font-size: 0.9em;
}

#resume .box .period i {
    color: #666;
    font-size: 1.1em;
    margin-right: 10px
}

#resume .box .arrow {
    font-size: 1.5em;
    color: #8a6474;
    font-weight: bold;
    position: absolute;
    right: 30px;
    top: 40px;
    transition: all 0.5s ease;
    outline: none;
    display: block;
}

#resume .box .rotated {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    transition: all 0.5s ease;
}

#resume .box .top {
    margin: 0 40px 0 0;
}

#resume .box .bottom {
    display: none;
    text-align: left;
}

#resume .box .bottom:before {
    content: ' ';
    display: block;
    border-top: 1px solid #ccc;
    margin: 20px 0 20px 0;
}

#resume .box .bottom .technologies {
    border-top: 1px solid #ccc;
    padding-top: 10px;
}

#resume .box .bottom .technologies ul {
    margin: 10px 0 0 0;
    padding: 0;
}

#resume .box .bottom .technologies ul li {
    background: #8a6474;
    color: #fff;
    padding: 3px 6px;
    margin: 0 5px 5px 0;
    border-radius: 3px;
    display: block;
    float: left;
    font-weight: 300;
}

#resume .nav {
    text-align: center;
    display: inline-block;
    margin-bottom: 30px;
}

#resume .nav li {
    color:#888;
    display: table-cell;
    vertical-align: middle;
    padding: 0 30px;
    cursor: pointer;
    -webkit-transition: color 0.2s linear;
    -moz-transition: color 0.2s linear;
    -o-transition: color 0.2s linear;
    transition: color 0.2s linear;
}

#resume .nav li:first-child,
#resume .nav li:last-child {
    padding:0;
}


#resume .nav li:hover {
    color: #666;
}

#resume .nav li span {
    display: block;
    margin-top:5px;
}

#resume .nav i {
    font-size: 2.2em;
}

#resume .nav .active,
#resume .nav .active:hover {
    color: #8a6474;
}

#resume .tab-content .tab-pane {
    display: none;
}

#resume .tab-content .active {
    display: block;
}


/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {

    #resume .item {
        padding-bottom: 20px;
    }

    #resume .bullet {
        width: 17px;
        height: 17px;
        background: #eee;
        border: 3px solid #8a6474;
        border-radius: 50%;
        display: block;
        position: absolute;
        color: #8a6474;
        top: 25px;
        left: 122px;
        z-index: 2;
        -webkit-transition: background 0.2s linear;
        -moz-transition: background 0.2s linear;
        -o-transition: background 0.2s linear;
        transition: background 0.2s linear;
    }

    #resume .bullet-active {
        background: #8a6474 !important;
    }

    #resume .box:hover ~ .bullet {
        background: #8a6474 !important;
    }

    #resume .item:after {
        background: #aaa;
        content: '';
        position: absolute;
        width: 2px;
        top: 0;
        bottom:0;
        left: 130px;
        z-index: 1;
    }

    #resume .item:first-child:after {
        top:30px;
    }

    #resume .item:nth-last-child(2):after {
        bottom:-30px;
    }

    #resume .item:last-child:after {
        display:none;
    }

    #resume .box {
        width: 75%;
    }

    #resume .box:after {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        border-right: 10px solid #fefefe;
        border-left: 10px solid transparent;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        left: -20px;
        right: auto;
        margin: 0;
        top: 22px;
    }

    #resume .box:hover:after {
        border-right: 10px solid #fff;
        border-bottom: 10px solid transparent;
    }

    #resume .box .period {
        position: absolute;
        left:-200px;
        top:18px;
        font-size: 1.1em;
        font-weight: 700;
        text-align: right;
        width: 120px;
    }

    #resume .box .period span {
        font-weight: 400;
        margin: 0 3px;
    }

    #resume .box .arrow {
        top: 22px;
    }

    #resume .box .period i {
        display: none;
    }

    #resume .box .company {
        font-size: 0.9em;
        line-height: 17px;
    }

    #resume .box ul {
        padding:0;
        margin:0;
    }

    #resume .nav li {
        padding: 0 50px;
    }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
    #resume .box {
        width: 78%;
    }

    #resume .bullet {
        left: 152px;
    }

    #resume .item:after {
        left: 160px;
    }
}
