#contact {
    background: #8a6474;
    overflow:hidden;
    height:100%;
    width:100%;
}

#contact h1 {
    color:#fff;
}

/* Contacts */

#contact .contacts {
    text-align:center;
    margin: 30 0;
}

#contact .item{
    display: inline-block;
    color: #fff;
}

#contact .contacts i {
    color: #ffffff;
    font-size: 60px;
    border-radius: 50%;
    width:100px;
    height:50px;
}