#profile {
    background: #fff;
    border-top:1px solid #fff;
    border-bottom:1px solid #fff;
    padding-left: 30px;
    padding-right: 30px;
}
#profile .introduction {
    font-size: 1.2em;
    color: #666666;
    line-height: 1.4em;
    text-align: left;
    font-weight: 300;
    margin-bottom: 20px;
}

/* Strengths */

#profile .strengths {
    text-align:center;
    margin: 30px 0;
}

#profile .strengths:last-child {
    margin-bottom:0;
}

#profile .strengths i {
    color: #8a6474;
    font-size: 40px;
    border-radius: 50%;
    width:100px;
    height:50px;
    padding-top: 30px;
}

@media (min-width: 768px) {
    #profile .introduction {
        font-size: 1.6em;
        margin-bottom: 60px;
    }

    #profile .item {
        margin:0;
    }
}
