body {
    font-family: "Open Sans", arial, sans-serif;
    font-weight: 400;
    line-height: 22px;
    color: #333333;
    font-size: 0.95em;
}

body:before {
    content: "";
    position: fixed;
    top: -10px;
    left: 0;
    width: 100%;
    height: 10px;
    z-index: 100;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .8);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, .8);
    box-shadow: 0 0 10px rgba(0, 0, 0, .8);
}

h1 {
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
    color: #333;
    font-size: 2em;
}

h1:after {
    content: "";
    display: block;
    background: #ccc;
    height: 1px;
    width: 100px;
    margin: 20px auto 60px auto;
}

h2 {
    font-weight: 400;
    color: #333333;
    font-size: 1.5em;
    text-transform: uppercase;
    margin: 0 0 20px 0;
    line-height: 18px;
}

h3 {
    color: #333333;
    padding: 0;
    margin: 0 0 20px 0;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.1em;
    line-height: 1.1em;
}


.shorter {
    margin-bottom: 0;
}

.short {
    margin-bottom: 10px;
}

.tall {
    margin-bottom: 30px;
}

.taller {
    margin-bottom: 40px;
}

.spaced {
    margin-top: 20px;
}

.more-spaced {
    margin-top: 40px;
}

hr {
    background-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.2), transparent);
    border: 0 none;
    height: 1px;
    margin: 20px 0;
}

hr.light {
    background-image: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.2), transparent);
}

hr.shorter {
    margin-bottom: 0;
}

hr.short {
    margin-bottom: 10px;
}

hr.tall {
    margin-bottom: 40px;
}

hr.taller {
    margin-bottom: 60px;
}

hr.spaced {
    margin-top: 40px;
}

hr.more-spaced {
    margin-top: 60px;
}

ul {
    padding-bottom: 20px;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
    color:  #8a6474;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

a:focus {
    outline: none;
}

a:hover {
    text-decoration: none;
    color: #5d8591;
}

p {
    margin: 0 0 20px;
}

video {
    width: 100% !important;
    height: auto !important;
}

input {
    font-weight:500;
}

textarea {
    outline-width: 0;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .container {
        width: 970px;
    }
}

#site {
    overflow: hidden;
    width: 100%;
}

section {
    padding: 80px 0 60px 0;
    position: relative;
}

/* General styles */
.spacer-smallest {
    height: 5px;
}

.spacer-smaller {
    height: 20px;
}

.spacer-small {
    height: 40px;
}

.spacer-tall {
    height: 60px;
}

.spacer-taller {
    height: 80px;
}

.spacer-tallest {
    height: 100px;
}

.light {
    color: #fff;
}

.dark {
    color: #333;
}

.highlight {
    color: #8a6474;
}

.lowlight {
    color: #666666;
}

.slim {
    font-weight: 300;
}

.bold {
    font-weight: 600;
}

.bolder {
    font-weight: 700;
}

.center {
    text-align: center;
}

.no-overflow {
    overflow: hidden;
}

#loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #8a6474; /* change if the mask should have another color then white */
    z-index: 99; /* makes sure it stays on top */
}

#loading .preloader {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%; /* centers the loading animation horizontally one the screen */
    top: 50%; /* centers the loading animation vertically one the screen */
    background-repeat: no-repeat;
    background-position: center;
    margin: -25px 0 0 -25px; /* is width and height divided by two */
}

@media (max-width: 768px) {

    [class*="col-"] {
        margin-bottom: 15px;
    }

}
