#home {
    background: #8a6474;
    color:#ffffff;
    text-align: center;
    position: relative;
    width: 100%;
    min-height: 850px;
}

#home h1,
#home h2 { color:#fff; }
#home h1:after { display: none; }
#home h2 { font-weight: 300; }

#home .portrait { width: 250px; height: 250px; margin:auto; border: 3px solid #fff; border-radius: 50% }

#home .name {
    font-family: "Oswald", arial, sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    margin-top:25px;
    font-size: 2.5em;
}

#home .line {
    border-top:1px solid #fff;
    height:2px;
    width:250px;
    position: relative;
    margin:20px auto 20px auto;
}

#home .dot {
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background:#fff;
    position: absolute;
    top:-7px;
    left:49%;
}

#home .title {
    text-transform: inherit;
    font-size: 1em;
    line-height: 2em;
    margin-bottom: 60px;
}

#home .button-wrapper {
    margin:20px auto;
    width:200px;
}

#home .button, .button:focus {
    display: block;
    border: 1px solid #ffffff;
    padding: 18px 0;
    transition: all 0.5s ease;
    text-decoration: none;
    outline: none;
    width: 100%;
    background:#ffffff;
    color:#8a6474;
    border-radius: 30px;
    margin:auto;
}

#home .toggle {
    position: relative;
    overflow: hidden;
}

#home .toggle input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    height: 60px;
    display: inline-block;
    border-radius: 50px;
    outline: none;
    border: none;
    cursor: pointer;
    background-color: #fff;
    transition: background-color ease 0.3s;
    z-index: 3;
}

#home .toggle .on,
#home .toggle .off,
#home .toggle .slider {
    color:#8a6474;
    position: absolute;
    transition-duration: 0.5s;
    pointer-events: none;
}

#home .toggle .on,
#home .toggle .off {
    top:18px;
}
#home .toggle .on {
    left:-70px;
}

#home .toggle .off {
    right:60px;
}

#home .toggle .slider {
    top:15px;
    left:15px;
    font-size: 30px;
}

#home .toggle input:checked ~ .slider,
#home .toggle input:checked ~ .on,
#home .toggle input:checked ~ .off {
    transform:translateX(140px)
}

/*
#home .toggle:before {
    content: "Dansk English";
    display: block;
    position: absolute;
    z-index: 2;
    width: 35px;
    height: 35px;
    background: ##8a6474;
    line-height: 38px;
    left: 12px;
    top: 12px;
    border-radius: 50%;
    text-indent: -80px;
    word-spacing: 105px;
    color: ##8a6474;
    white-space: nowrap;
    transition-duration: 0.5s;
    -webkit-transform: translateX(140px);
    -moz-transform: translateX(140px);
    -ms-transform: translateX(140px);
    -o-transform: translateX(140px);
    transform:translateX(140px);
}
*/

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px){
    #home {
        padding:130px 0 80px 0;
    }

    #home .name {
        margin:50px 0 25px 0;
        font-size: 3.5em;
    }

    #home .portrait {
        width: 300px;
        height: 300px;
        margin:auto;
    }

    #home .line {
        width:400px;
    }

    #home .title {
        font-size: 1.2em;
    }

    #home .button-wrapper, .button-wrapper:focus {
        position: absolute;
        top: 100px;
    }
    #home .button:hover  {
        background:#ffffff;
        color:#8a6474;
        text-decoration: none;
    }

    #home .download { left:-20px; }
    #home .language { right:-20px; }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
    #home .button, .button:focus { width: 200px; top: 170px; }
    #home .portrait { width: 400px; height: 400px; margin:auto; }
    #home .download { left:20px; }
    #home .language { right:20px; }
    #home .button-wrapper, .button-wrapper:focus {
        position: absolute;
        top: 150px;
    }
}
